@import "../../pages/common/variables";
@import "../../pages/common/carrousel-images";

.binstore {
  opacity: 0.2;
  pointer-events: none;
}

.item {
  //  background-color: #F9F9F9;
  height: 30em;
  //swidth: 18em;
  margin-bottom: 1em;
  border-radius: 1em;
  // border: 1px solid $sixth-colour;
  background-color: $fourth-colour;

  &:hover {
    .ct-time-estimated {
      display: none;
    }
  }

  .head-item {
    cursor: cell;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 1em;

    &:hover {
      background-color: $second-colour;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;

      .text-item {
        color: $fourth-colour;
      }

      .dron-content {
        background-image: url("../../rsc/assets/vector-drone5.gif");
      }
    }

    .text-item {
      overflow: hidden;
      flex: 1 1;
      color: #ff4b4c;

      &:hover {
        // flex-basis: auto;
      }

      h3 {
        overflow: hidden;
      }

      &:focus {
        overflow: initial;
        flex-basis: auto;
        margin: 0;
        padding: 0;
        text-overflow: initial;
        display: flex;
        height: 2em;
        align-items: center;
        justify-content: center;
      }
    }

  }

  .image-item {
    cursor: zoom-in;
    cursor: -moz-zoom-in;
    height: 55%;
    background-repeat: no-repeat;
    border-bottom: 0.5px solid #b9151a;
    border-top: 0.5px solid #b9151a;
    justify-content: center;
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    background-position-x: center;
    background-position-y: center;
    margin: auto;

    background-size: cover; /* La imagen cubrirá todo el contenedor */
    // width: 400px;
    // height: 300px;
  }
    &:active {
      background-color: $second-colour;
    }

    .content-carrousel {
      height: 65%;

      .slick-slider {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
      }
     
    }
  }


  .bs-ct {
    background-size: contain;
  }


  .content-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 45%;
    margin: auto;
    height: 3.5em;
    border-radius: 5em;

    .add-product {
      background-image: url('../../rsc/assets/icon-add-black.png');
      height: 3em;
      width: 3em;
      background-size: cover;
    }

    .add-to-store {
      background-image: url('../../rsc/assets/icon-store-add-black.png');
    }

    &:hover {
      -webkit-box-shadow: inset 0px 0px 19px -11px $fourth-colour;
      -moz-box-shadow: inset 0px 0px 19px -11px $fourth-colour;
      box-shadow: inset 0px 0px 19px -11px $fourth-colour;
      cursor: pointer;
      color: $primary-colour;
      justify-content: flex-end;
    }
  }

  .cb-two {
    height: 2.5em;
    width: 30%;
    background-color: $fourth-colour;
    border: none;
    box-sizing: content-box;
    font-size: .7em;
    font-weight: 600;

    &:hover, &:active {
      background-color: $fourth-colour;
      color: $primary-colour;


      span {
        font-size: .9em;
        font-weight: 600;

      }
    }

    span {
      font-size: .7em;
    }
  }


.item2 {
  max-height: 33em;
  width: 20em;
  min-width: 17em;
  max-width: 28em;
  min-height: 15em;
}

.item3 {
  min-width: 17em;
  max-width: 28em;
  max-height: 30em;
  min-height: 15em;
  width: 100%;
  margin: auto;
}

.dron-content {
  background-image: url("../../rsc/assets/vector-drone4.gif");
  background-position-x: center;
  background-position-y: center;
  background-size: 10em;
  height: 7em;
  width: 7em;
  opacity: 0.0;
  position: absolute;
  bottom: 0;
  left: 0;


}

.moved {
  display: flex;
  transform: translate(9.5em, -26.5em);
  transition-duration: 3s;
  opacity: 1.0;


}

.item-hov-mos {
  .head-item {
    //background-color: $second-colour;

  }

  border: 3px solid $second-colour;
  min-width: 16em;

  .image-item {
    border-color: $fourth-colour;
  }
}

.icon-close-selected {
  position: absolute;
  bottom: 95%;
  left: 88%;
  background-color: $fourth-colour;
  color: $primary-colour;
  border: 1px solid $zero-colour;
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-delay: 3s;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  cursor: pointer;

  &:hover {
    background-color: $fourth-colour;
  }
}

.icon-break-selected {
  background-image: url("../../rsc/assets/logoArtesanias1.svg");
  background-position-x: center;
  background-position-y: center;
  background-size: 16em;
  height: 100%;
  width: 110%;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;

}


.body-bag {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}


.ch-foot-inp {
  font-size: inherit;
  border: 0;
  box-shadow: none !important;
  background-color: transparent;
  color: #1b2e4b;
  align-self: center !important;
  display: block;
  width: 45%;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ds-fl {
  display: flex;
}

.fott-it-br {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.chat-content-footer {
  display: flex;
  justify-content: space-around;
  font-size: .8em;
  padding-right: 1em;
  height: 2em;
  align-items: center;

  .icontf {
    -webkit-mask-size: 1.5em;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position-y: center;
    background-color: #757575;
    width: 2em;
    height: 1.7em;
  }

  .ic-comment {
    -webkit-mask-image: url("../../rsc/assets/comentario.svg");


  }

  .ic-plus {
    -webkit-mask-image: url("../../rsc/assets/mas.svg");
  }

  .ind-cnt {
    color: #757575;
  }
}

.estrella {
  background: url(../../rsc/assets/stair-vacia.png) no-repeat center center;
  background-size: 13px 12px;
  display: inline-block;
  height: 12px;
  margin-left: 1px;
  width: 13px;
}

.llena {
  background: url(../../rsc/assets/stair-llena.png) no-repeat center center;
  background-size: 13px 12px;
}

.media {
  background: url(../../rsc/assets/stair-media.png) no-repeat center center;
  background-size: 13px 12px;
  opacity: 1;
}

.vendidos {
  color: #054d98;
}

.content-view-products {
  position: absolute;
  background-color: $zero-colour;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  border-radius: .5em;
  z-index: 1;
}

.content-sold-out {
  color: white;
  font-size: xx-large;
  font-weight: 100;
  background-color: $fourth-colour;
  opacity: 0.6;
  border-inline: 3px solid $zero-colour;

  span {
    text-shadow: 2px 2px 4px $second-colour;
    font-size: 1.3em;
    font-style: oblique;
    font-weight: 900;
    width: 100%;
    text-align: center;
    background-color: #ff9795;
    padding: .5em;
  }

}

.content-contenido {
  overflow: auto;
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: min-content;
  height: 78%;
  flex-direction: column;
  margin: 1em 2em;
  flex-wrap: wrap;
  padding-top: 2em;

  span {
    line-height: 1em;
    color: $fourth-colour;
  }
}

.two-colums {
  grid-template-columns: auto auto;

}

.ic-close {
  position: absolute;
  right: .3em;
  font-size: 2em;
  color: $fourth-colour;
  top: .1em;
  cursor: pointer;
}

.title-desc-b {
  color: $fourth-colour;
  top: .3em;
  position: absolute;
  /* left: auto; */
  /* right: auto; */
  left: 33%;
  font-size: 1.1em
}

.media-inf {
  display: flex;
  align-items: flex-start;
  color: $fourth-colour;
  margin-bottom: 1em;

  .avatar {
    position: relative;
    width: 38px;
    height: 38px;
    margin-right: 1em;
  }

  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .avatar-initial {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8392a5;
    color: $fourth-colour;
    font-weight: 700;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-offline::after,
  .avatar-online::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 2px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    box-shadow: 0 0 0 2px $fourth-colour;
  }

  .avatar-offline::after {
    background-color: #c0ccda;
  }

  .avatar-online::after {
    background-color: #10b759;
  }

  .avatar-xs {
    width: 26px;
    height: 26px;
  }

  .avatar-xs .avatar-initial {
    font-size: 10px;
  }

  .avatar-xs.avatar-offline::after, .avatar-xs.avatar-online::after {
    width: 5px;
    height: 5px;
  }

  .avatar-sm {
    width: 32px;
    height: 32px;
  }

  .avatar-sm .avatar-initial {
    font-size: 13px;
  }

  .avatar-sm.avatar-offline::after, .avatar-sm.avatar-online::after {
    width: 7px;
    height: 7px;
  }

  .avatar-md {
    width: 48px;
    height: 48px;
  }

  .avatar-md .avatar-initial {
    font-size: 18px;
  }

  .avatar-md.avatar-offline::after, .avatar-md.avatar-online::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px;
  }

  .avatar-lg {
    width: 64px;
    height: 64px;
  }

  .avatar-lg .avatar-initial {
    font-size: 24px;
  }

  .avatar-lg.avatar-offline::after, .avatar-lg.avatar-online::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px;
  }

  .avatar-xl {
    width: 72px;
    height: 72px;
  }

  .avatar-xl .avatar-initial {
    font-size: 30px;
  }

  .avatar-xl.avatar-offline::after, .avatar-xl.avatar-online::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px $fourth-colour;
  }

  .avatar-xxl {
    width: 100px;
    height: 100px;
  }

  .avatar-xxl .avatar-initial {
    font-size: 45px;
  }

  .avatar-xxl.avatar-offline::after, .avatar-xxl.avatar-online::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px $fourth-colour;
  }

  .media-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.rounded-circle {
  border-radius: 100%;
}

.mg-0 {
  margin: 0;
}

.ta-e {
  text-align: end;
}


.content-califications {
  .ct-cal-view1 {
    display: none;
    flex-direction: column;
  }

  &:hover {
    .ct-cal-view0 {
      display: none;
    }

    .ct-cal-view1 {
      display: flex;
    }

    strong, h6 {
      text-align: center;
    }
  }
}

.fz-xs {
  font-size: x-small;
}

.mg-1 {
  margin: 1em;
}

.d-f {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.ct-view-bag {
  display: flex;
  justify-content: center;
  position: absolute;
  color: black;
  letter-spacing: -1px;
  top: 79%;
  left: 2%;
  cursor: pointer;

  .icon-view {
    -webkit-mask-image: url('../../rsc/assets/eye.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 1.3em;
    height: 1.3em;
    color: #ff4b4c;
    background-color: #ff4b4c;

  }

  span {
    display: none;
  }
  &:hover {
    background-color: white;
    padding: 0 1em;
    border-top-left-radius: .4em;
    border-top-right-radius: .4em;

    span {
      display: block;
      color: black;

    }
    .icon-view {
      -webkit-mask-image: url('../../rsc/assets/eye-shape-.svg');
      -webkit-mask-size: 1.5em;
      width: 1.5em;
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      background-color: black;
    }


  }
}
.ct-time-estimated {
  display: flex;
  justify-content: center;
  position: absolute;
  color: #ff4b4c;
  letter-spacing: -1px;
  top: 3px;
  right: 1%;
  .icon-time {
    -webkit-mask-image: url('../../rsc/assets/time.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 1.3em;
    height: 1.3em;
    background-color: #ff4b4c;
  }

  .tx-time {
    font-size: small;
    padding-right: 0.3em;
  }
}

.btn-success {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: rgba(100, 150, 200, .5);
  color: #fff;
  font-size: 1.5em;
  padding: .5em;
  cursor: pointer;
}
