@import "../../pages/common/variables";

.title-page {
  color: $fourth-colour;
  flex: 3;
  text-align: center;
  margin: initial;
}


.content-view {
  height: 100%;
  position: relative;
  width: 100%;

}

.content {
  display: flex;
  flex-direction: row;
  height: 88%;
  padding: 3em 0 0 0;
}

.content-men {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;

  ul, ol {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 67%;
  }
}

.content-body {
  flex: 3;
}

.active {
  color: $primary-colour;

  a {
    color: $primary-colour;
  }
}