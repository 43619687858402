@import "../../pages/common/variables";


body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}
.container {
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.logo {
  width: 200px;
  display: block;
  margin: 0 auto;
}
.title {
  text-align: center;
  color: green;
  font-size: 24px;
  margin-top: 20px;
}
.yellow {
  color: yellow;
}
.red {
  color: red;
}
.details {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label {
  font-weight: bold;
}
.value {
  color: gray;
}
.button {
  display: block;
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}
.b-yellow {
  background-color: yellow;
}
.b-red {
  background-color: red;
}