@import "../../pages/common/variables";

.icon-menu {
  background-position: center;
  width: 3em;
  height: 3em;
  background-size: 2em;
  background-repeat: no-repeat;
  background-image: url("../../rsc/assets/menu.svg");
  z-index: 1;
  border-radius: 100%;
  padding: .2em 0;
  margin: .5em;
}

.icon-menu-white {
  background-image: url("../../rsc/assets/menu_white.svg");

}
.itm-menu-active {
  right: 0;
  height: 3em;

}

.menu-ls-active {
  height: 100%;
  flex-wrap: wrap;
}

.img-comp {
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  height: 10em;
  width: 10em;
  flex: 1 1;
  background-image: url("../../rsc/assets/logoArtesanias1.svg");

  h2 {
    opacity: 0;
  }
}

.icon-search {
  background-image: url('../../rsc/assets/search.png');
  height: 1.7em;
  width: 1.7em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.button-search {
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cnt-serv-act {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(135deg, $zero-colour 0%, #1d1d1d 100%);

  z-index: 100;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;

  span {
    color: white;
  }

  .content-modalities {
    // width: 80% !important;
    // height: 100% !important;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin: 10px;
      padding: 15px;
      display: inline-block;
      background-color: #fff;
      color: #333;
      font-size: 1.2rem;
      border-radius: 8px;
      transition: all 0.3s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #f5f5f5;
        transform: translateY(-5px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
      }

      li {
        background-color: white !important;
        h3 {
          color: black;
        }
      }
    }

  }
}

.content-slide-menu {
  flex: 8;
  display: flex;
  flex-direction: row;
  height: 1em;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0;

  color: $second-colour;

  .content-modalities {
    justify-content: space-evenly;
    align-items: center;
    // height: 4em;
  }

  li {
    cursor: pointer;
    margin: 0 0.5em;

    h3 {
      color: $second-colour;
    }

    .ic-services-ic {}

    .is-gift {}

    &:hover {
      font-size: 1em;

      h3 {
        color: $second-colour;
      }
    }
  }

  .is-active {
    font-size: 1.4em;
    color: $primary-colour;
  }
}