@import "../../../pages/common/variables";

.body-header {
  // background: linear-gradient(135deg, $zero-colour 0%, #1d1d1d 100%);

  background: linear-gradient(135deg, #1d1d1d 0%, $zero-colour 100%);
  color: $zero-colour;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid $zero-colour;
}

.header {
  // background: linear-gradient(135deg, $zero-colour 0%, #1d1d1d 100%);

}

.head-view {

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5em 3em;
  //border-bottom: 1px dashed gray;
  flex-wrap: wrap-reverse;
  margin: 0.5em;
  max-height: 3em;


  .content-title {
    cursor: pointer;
    background-image: url("../../../rsc/assets/logoArtesaniasTextWhite.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 3.5em;
    width: 10em;
    background-position-y: -3em;
    flex: 1 1;

    h2 {
      display: none;
    }

    .title-artesaniasgb {
      color: green;
      font-weight: lighter;
      font-style: italic;
      font-variant-caps: small-caps;
      font-size: x-large;
    }

    .icon-artesaniasgb {
      background-image: url("../../../rsc/assets/logoArtesanias1.svg");
    }
  }

  .content-icons {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex: 1;

    .icon {
      display: inline-block;
      margin: 0 5px;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  .subtitle {
    color: #5c5c5c;
    font-size: 12px;
    font-weight: lighter;
  }


}


.icons-rs {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  div {
    margin: 0 .4em;
    cursor: pointer;
    height: 2em;
    width: 2em;
    background-size: 2em;
    background-repeat: no-repeat;
  }

  .space {
    width: 1em;
  }


  .ins {
    background-image: url("../../../rsc/assets/instagram.svg");
    padding: .17em;
    background-position: center;
    border-radius: .3em;
  }

  .wapp {
    background-image: url("../../../rsc/assets/whatsapp.svg");
  }

  .fbook {
    background-image: url("../../../rsc/assets/facebook.svg");
  }

  .counter-shop {
    width: auto;
    margin: 0.6em 1.5em 0 0;
    position: absolute;

      
    // left: 2em;
  }

  .shop {
    width: 2em;
    background-repeat: no-repeat;
    background-image: url("../../../rsc/assets/store.svg");
    background-size: 2em;
    background-position-y: 55%;
    position: absolute;
    height: 2em;
    top: -10px;
    right: -5px;
    background-color: #ececec;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    background-position: center;

    &:hover {
      background-image: url("../../../rsc/assets/storeh.svg");
    }
  }

  .item-disable {
    opacity: 26%;
    cursor: no-drop;
    font-size: .8em;
  }

  .item-active {
    font-size: 1.3em;
  }

}

.mr-4 {
  margin-right: 4em;
}

.hidden {
  display: none;

  a {
    display: none;
  }
}


.fd-r {
  flex-direction: row;
}

.ct-info-sec {
  display: flex;
  justify-content: center;
  height: 3em;
  align-items: flex-end;
}

@media (max-width: 950px) {
  .head-view {
    justify-content: center;
    align-items: center;
    max-height: 12em;
    padding: 1.7em 0 1.3em;
    display: flex;
    flex-direction: column;

    .content-sub-header {


      .content-bar-search {
        width: 100%;
      }
    }
  }

  .hv-store {
    display: flex;
    flex-direction: column;
  }

  .body-header {
    height: auto;
    background: linear-gradient(135deg, #1d1d1d 0%, $second-colour 100%);

  }

  .ct-info-sec {
    .col-th{
      color: white;
    }
  }
}

@media (max-width: 700px) {

  .header {

  }
  .icons-rs {
    .shop {
      background-color: $fourth-colour;
    }
  }

}

.d-f {
  display: flex;
}

.m-n {
  margin: 0;
}

.ml--1 {
  margin-left: .5em;
}

.tx-presentation {
  background-image: url("../../../rsc/assets/logoArtesaniasIcon2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2em;
  height: 3em;
  h1 {
    opacity: 0.0;
  }
}

.content-select_country {
  width: 71%;
}

