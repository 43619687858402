@import "../../pages/common/variables";


@media (max-width: 700px) {

  .content-store {
    height: auto;


  }

  .body-p-suggestion {
    border: none;
  }
  .body-p-suggestion {


    .ct-image-forgotten {

      .cnt-image-res {
        border-top-left-radius: 3em;
      }
    }
  }


}

.content-store-shop {
  width: 100%;
  height: 100%;
  display: flex;


}

.col1-store-s {
  flex: 3;

}

.col2-store-s {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1em;

  .notify-note {
    font-size: 1em;
    margin-bottom: 1em;
  }

  .text-price {
    color: #5ACEB4;

  }


}

.button-continue {
  cursor: pointer;
  text-align: center;
  padding: .7em;
  border: 1px solid #cccccc;
  border-radius: 0.2em;
  background: rgb(155, 236, 222);
  // background: linear-gradient(90deg, rgba(155, 236, 222, 1) 8%, rgba(128, 178, 201, 1) 32%, rgba(201, 147, 128, 1) 96%);
  font-size: 1.5em;
  font-weight: 900;
  color: $fourth-colour;
  margin-top: .7em;

  background: linear-gradient(90deg, #ff4b2b 10%, #ff416c 90%);
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Sombra suave para resaltar el texto */

}

.w-98 {
  width: 98%;
}

.content-store {
  width: 100%;
  overflow: auto;
}

.content-informacion {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.msg-dest {
  font-size: 1.3em;
  color: #b9151a;
  display: flex;
  line-height: 1em;
}

.content-item-info {
  width: 25%;
}

.img-ch {
  background-size: contain;
  background-repeat: no-repeat;
  width: 6em;
  height: 6em;
  -webkit-mask-size: contain;
  background-position: center;
  -webkit-mask-position: center;

}

.img-compraseg {
  width: 10em;
  background-image: url("../../rsc/assets/comprasegura.png");
  -webkit-mask-image: url("../../rsc/assets/comprasegura.png");
}

.img-payu {
  background-image: url("../../rsc/assets/payy.png");
  -webkit-mask-image: url("../../rsc/assets/payy.png");
}

.img-epayco {
  background-image: url("../../rsc/assets/epayco.png");
  -webkit-mask-image: url("../../rsc/assets/epayco.png");
}

.img-compraseg {
  background-image: url("../../rsc/assets/comprasegura.png");
  -webkit-mask-image: url("../../rsc/assets/comprasegura.png");
}

.imgSSL {
  background-image: url("../../rsc/assets/SSLsecure.png");
  -webkit-mask-image: url("../../rsc/assets/SSLsecure.png");
}

.content-pay {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  //border-left: 1px dashed lightgray;
  //border-right: 1px dashed lightgray;
  padding: 0em 3em;

  .content-data-pay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    max-height: 27em;

    .content-data-mthd-pay {
      max-height: 15em;
      min-height: 15em;
      overflow: auto;
    }
  }
}

.content-info-pay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
  flex: 1;

  .header-step {
    margin: 0;
    padding: 1em;
  }
}

.content-title {
  display: flex;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;

}

.content-carousel {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  right: -5em;
  position: relative;


  div {
    max-width: 63em;
    min-width: 8em;

  }

  .sc-EHOje, .clcBSf, .sc-bZQynM, .dPzsDe {
    max-width: 1em;
    min-width: 1em;
  }

  &:last-child {
    left: 0em;
    width: 100%;
  }
}

.content-place-caro {
  width: 150px;
  height: 150px;
  background: $primary-colour;
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
}


.sc-bdVaJa, .imYUvI {

  padding: 0 1em;
}

.sc-bZQynM, .dPzsDe {
  color: $primary-colour;
  border-right: dashed;
}

.sc-EHOje, .clcBSf {
  color: $primary-colour;
  border-left: dashed;
  width: 1em;

}

.butt-ind-right {
  display: none;
  position: absolute;
  right: 33px;
  bottom: 17px;
  font-size: 4em;
  color: $primary-colour;
  cursor: pointer;

  &:hover {
    background-color: $fourth-colour;
    opacity: 0.7;
  }
}

.content-item-suggestions {
  border-radius: 100%;
  height: 150px;
  width: 150px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;

  .content-info-item {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.3em;

    span {
      font-size: 1em;
      color: $zero-colour;
      font-weight: 900;
      font-variant-caps: all-petite-caps;
      text-align: center;
    }
  }

  h5 {
    text-transform: uppercase;
  }


  .content-item-caro {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    height: 8em;
    width: 8em;

  }

  &:hover {

    .content-item-caro {
      opacity: 0.8;
    }

    .content-info-item {

      display: flex;
      position: absolute;
      justify-content: flex-start;

      span {
        color: $second-colour;

      }

    }

    .content-info-suggestion {
      color: $second-colour;

    }
  }

}


.active-mp {
  border-top: 3px solid $second-colour;
  border-bottom: 3px solid $second-colour;

  .content-item-caro {
    opacity: 0.2;
  }

  .content-info-item {
    display: flex;
  }
}

.content-header-pay3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: .5px dashed #eaeaea;

  height: 30%;

  .body-p-suggestion {
    padding-top: .3em;
    border-top-left-radius: 2em;

    &:hover {
      padding: 0;
      background-color: transparent;
      border: none;

      .title-sugg {
        display: none;
      }
    }
  }

  .header-step {

    width: 50%;
  }


  .style-pzed {
    border: 2px solid $primary-colour;
    border-bottom: none;
    border-right: none;
    border-top-left-radius: 5em;
  }


}

.title-total-3 {
  //right: 10em;
  text-align: end;
  //position: absolute;
  font-size: 1.3em;
  font-weight: 900;
  color: $second-colour;
  margin: auto 1em;
  background-color: #9becde;
  padding: 1em;
  border-radius: 5em;
}

.note-fields-obli {
  display: flex;
  justify-content: flex-end;
  color: $primary-colour;
}

.title-methodpay {
  margin: 2em 0 1em 0;
  color: darkslategray;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}

.c-w {
  color: $fourth-colour;
}

.tit-carr {
  margin: 1.5em 0 .5em 0;

}

.title-min-targ {
  font-weight: bolder;
  color: #d7bf59;
  max-height: 2em;
  overflow: auto;
}

.section-middle-pay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cnt-colm2-delivery {
  //background-color: $second-colour;
  border-radius: 16em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 51%;

  .react-datepicker-wrapper {
    cursor: pointer;
    width: 80%;

    .react-datepicker__input-container {

    }
  }

  input {
    cursor: pointer;
    text-align: center;
    display: flex;
    height: 3em;
    width: 97%;
    align-items: center;
    border-radius: 1em;
    margin-top: .3em;
    border-bottom: .0625em solid #d0ccc6;
    flex: 1;
    opacity: 0.4;
    font-size: 0.875em;
    line-height: 1.43;
    letter-spacing: normal;
    color: $zero-colour;
    margin-left: 1em;
  }
}

.content-icon-truck {
  width: 29em;
  height: 16em;
  margin: 0 1em 1em 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  overflow: scroll;
  background-image: url("../../rsc/assets/truck-store.png");
  background-size: 33em;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position: center;

  .content-items-truck {
    width: 67%;
    display: flex;
    overflow: auto;
    margin-left: 1.3em;
  }
}

.cnt-item-res {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fee784;
  padding: .5em;
  border-radius: 11%;
  border: 2px solid #d7bf59;
  margin-bottom: 5em;
  margin-left: 2em;
}

.cnt-image-res {
  height: 4em;
  width: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid $fourth-colour;
}

.content-info-suggestion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-title-mp {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.icon-change {
  width: 1em;
  height: 1em;
  background-image: url("../../rsc/assets/change.png");
  background-size: 1em;
  background-position: center;
  background-repeat: no-repeat;
  margin: 1.5em 0 0 .7em;
}

.txt-terms-header {
  font-size: 0.9em;
  margin: 0;
  color: $primary-colour;
  width: 110%;

  span {
    margin-left: 1.5em;
  }

  input {
    outline: 1px solid $primary-colour;
    -moz-outline-radius: .2em;
  }

  & input[type="checkbox"] {
    width: 2em;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
  }

  & label {
    position: relative;
  }

  & label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    width: 15px;
    height: 15px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    border: 2px solid $primary-colour;
  }

  & label:after {
    content: '';
    display: block;
    width: 7px;
    height: 3px;
    border-bottom: 2px solid $primary-colour;
    border-left: 2px solid $primary-colour;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 5px;
    left: 5px;
  }

  & input[type="checkbox"]:checked ~ label::before {
    color: $primary-colour;
  }

  & input[type="checkbox"]:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }

}

.txt-message-header {
  margin: 0;
  color: gray;
  padding-left: .5em;
  width: 100%;
}

.txt-mail-header {
  margin: 0;
  font-size: 0.8em;
  color: gray;
  padding-left: .7em;
  width: 100%;
}

.btn-add-forgotten {
  padding: .5em;
  justify-content: center;
  align-items: center;
  border-top: .5px solid #c29586;
  color: #c29586;
  cursor: pointer;
  display: none;
}

.ct-image-forgotten {
  position: relative;

}

.ct-img-forg {
  height: 8em;
}

.ic-c-forg {
  position: absolute;
  top: 13px;
  left: -4px;
  cursor: pointer;
  z-index: 1;
  display: none;

  span {
    margin-left: 5.5em;
    font-weight: 600;
    display: none;
  }

  &:hover {
    background-color: $fourth-colour;
    padding: .3em;
    border-radius: 1em;

    span {
      display: block;
    }

  }
}

.content-item-forg {
  //outline: 0.5px solid #c29586;
  width: 11em;
  position: relative;

  &:hover {
    outline: none;

    .ic-c-forg {
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .ct-txt-forg {
      background-color: #91d6d6;
      color: $fourth-colour;
      font-weight: 900;
      font-variant: all-petite-caps;
      display: flex;
    }

    .ct-image-forgotten {
      background-color: #b09d99;
      color: $fourth-colour;

      .cnt-image-res {
        border-top-left-radius: 6em;
      }
    }

    .btn-add-forgotten {
      background-color: $fourth-colour;
      color: $second-colour;
      //opacity: 0.5;
      font-size: 1.2em;
      font-weight: 900;
      font-variant: all-petite-caps;
      display: flex;
      border: 1px solid black;
      border-bottom-right-radius: 1em;
    }
  }
}

.ct-txt-forg {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #c29586;
  height: 2em;
  display: none;
}

.title-sugg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  color: $zero-colour;
  margin-bottom: .2em;
}

.item-car-sugg {
  height: 90%;
  cursor: pointer;

  .tit-acc {
    color: $primary-colour;
    font-size: 1.2em;
    display: none;
  }

  &:hover {
    .tit-acc {
      display: block;
    }
  }
}


@media (max-width: 1050px) {
  .content-store-shop {
    flex-direction: column;
    height: auto;
  }

  .content-carousel:last-child {
    //swidth: auto;
  }
}


@media (max-width: 700px) {

  .content-informacion {
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-item-info {
      width: 90%;
    }

  }

}

@media (max-width: 1500px) {

  .content-carousel {

    div {
      max-width: 40em;
    }
  }

}


@media (max-width: 1200px) {


  .column-create-bag {
    width: 100%;
  }
  .body-content-view {
    .cv2 {
      height: auto;
      display: block;
      BORDER: NONE;
    }

  }

  .content-item-store {
    .content-price {
      flex-direction: row;
    }
  }
  .content-item-forg {
    width: 17em;
  }
  .title-total-3 {
    width: 88%;
    text-align: center;
  }

  .txt-terms-header {
    margin-top: 2em;
  }

  .content-icon-truck {
    overflow: scroll;
    width: 99%;
    background-size: 100%;

    .content-items-truck {
      width: 42%;
      margin-left: 20%;
    }

    .cnt-item-res {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #fee784;
      /* padding: .5em; */
      border-radius: 11%;
      border: 2px solid #d7bf59;
      margin-bottom: 3em;
      margin-left: 1em;
      height: 4em;
    }

    .cnt-image-res {
      width: 2em;
      height: 22em;

    }

    .title-min-targ {
      height: 7px;
      font-size: 0.6em;

    }
  }

  .cnt-colm2-delivery {
    width: 100%;
    margin-top: 3em;
  }

  .content-header-pay3, .section-middle-pay {
    flex-direction: column;
    padding: 4em 0;
    border-bottom: 2px solid $primary-colour;

    .body-p-suggestion {
      //width: 63%;
    }

    .header-step {
      padding: 2em;
      width: 80%;
    }
  }

  .content-pay {
    .content-data-pay {
      width: 100%;
    }
  }


}

@media (max-width: 1000px) {

  .content-carousel {

    div {
      max-width: 35em;

    }
  }

}

@media (max-width: 400px) {

  .content-icon-truck {
    .cnt-item-res {
      height: 3em;
    }
  }


  .content-item-suggestions {

    .content-item-caro {
      height: 7em;
      width: 7em;
    }
  }
  .content-carousel {

    div {
      min-width: 7em;

    }
  }


}


.style-pzed {
  border: 2px solid $primary-colour;
  border-bottom: none;
  border-right: none;
  border-top-left-radius: 5em;
}


.header-step {
  display: flex;
  flex-direction: column;
  margin: 2em 1em 1em 1em;
  // border: 3px solid #b9151a;
  border-radius: 1em;
  padding: 1.7em 0em;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  justify-content: space-between;

  max-height: 30%;
  justify-content: center;
  align-items: center;

  .title-hstep {
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: .5em;

  }

  .desc-hstep {
    font-size: 1.3em;
    font-style: italic;
    /* opacity: 25%; */
    font-weight: 100;
    color: #b9151a;
  }
}

@media (max-width: 600px) {
  .content-title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
