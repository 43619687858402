@import "../../pages/common/variables";

.content-body {
  font: status-bar;

  &:hover {
    .info-publication {
      font-size: large;
      background-blend-mode: multiply;
      background-size: 1em;
      font-weight: 700;
    }
  }
}

.info-publication {
  white-space: pre-line;
  color: $zero-colour;
  font-size: x-small;
  vertical-align: text-top;
  text-align: justify;
  padding: 0 2em;

  &:hover {
    font-size: large;
    background-blend-mode: multiply;
    background-size: 1em;
    font-weight: 700;
  }
}

.image-publication {
  width: 51%;
  float: left;
  padding: 2em;
}