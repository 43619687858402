@import "../../pages/common/variables";

.content-info-alert {
  position: absolute;
  background-color: $fourth-colour;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .content-alert {
    //border: 4px solid $primary-colour;
    padding: 2em;
    max-height: 70%;
    position: relative;


    .btn-close {
      position: absolute;
      right: 1em;
      top: 1em;
      font-size: 1.3em;
      color: $fourth-colour;
      cursor: pointer;
    }

    .content-dody-cat {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    .title-alert {
      height: 10%;
      font-size: 2.5em;
      padding-bottom: 1em;
    }

    .title-auxiliary {
      height: 10%;
      font-size: 1.5em;
    }
  }

  .info-image {
    background-size: contain;
    height: 80%;
    width: 80%;
    background-repeat: no-repeat;
    background-position: center;
  }

}