.content-items-filter {
  display: flex;
  flex-direction: column;


  &:hover {
  }
}

.content-info-filter {
  &:hover {
    border: 1px solid gold;
    border-radius: 0.3em;

    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
  }
}