@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

$primary-font: "Roboto", sans-serif;
$bgnd: #fafafa;
$primary-colour: #BF2828;
$second-colour: #000;
$zero-colour: #B9151A;
$third-colour: #054d98;
$fourth-colour: #fff;

$fifth-colour: #FF9999;
$sixth-colour : #848484;

@mixin fontandline{
  font: 14px/12px;
}

html,
body {
  height: 100%;
}

.col-th {
  color: $primary-colour;
}

body {
  font-family: $primary-font;
  margin: 0;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  // font-variant: small-caps;
}

abbr {
  text-decoration: none;
}

.wid-100 {
  width: 100%;
}

li {
  list-style-type: disclosure-closed;
  color: #000;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $primary-colour;
  text-shadow: 0 0 $second-colour;

  &:hover, &:focus {
    color: $second-colour;
    opacity: 1.0;
  }
}

.d-f {
  display: flex;
}

.fd-c {
  flex-direction: column;
}

.jc-sa {
  justify-content: space-around;
}

.ai-c {
  align-items: center;
}

.jc-c {
  justify-content: center;
}

.c-t {
  color: $third-colour;
}
.c-f {
  color: $fifth-colour;
}
.c-pr {
  color: $primary-colour;
}

//widdths

.w-10 {

  width: 10em;

}

.w-11 {

  width: 11em;

}

.w-1 {

  width: 1em;

}

.w-0 {

  width: 0;

}

.cur-p {
  cursor: pointer;
}

article {
  p {
    white-space: pre-line;
    text-align: justify;
  }
}

.to-e {
  text-overflow: ellipsis;
}

.m-0 {
  margin: 0;
}
.m-01 {
  margin: 0 1em;
}

.px-2 {
  padding: 0 2em ;
}
.py-2 {
  padding: 2em 0;
}
.py-0040 {
  padding: 0 0 4em 0;
}
.py-2040 {
  padding: 2em 0 4em 0;
}

//sizes
.h-1-5 {
  height: 1.5em;
}
.h-2-5 {
  height: 2.5em;
}

//colors

.c-b {
  color: $zero-colour;
}

.c-w {
  color: $fourth-colour;
}

.c-g {
  color: grey;
}

.c-br {
  color: #c89380;
}

//opaccity

.o-0 {
  opacity: 0;
}
.o-1 {
  opacity: 1;
}

.o-05 {
  opacity: 0.5;
}

.o-03 {
  opacity: 0.3;
}

.o-02 {
  opacity: 0.2;
}

//buttons
.btn-third {
  &:hover {
    color: $second-colour;
    font-weight: 900;
  }
}


.ov-au {
  overflow: auto;
}

.ta-c {
  text-align: center;
}

.tt-i {
  text-transform: inherit;
}

.pe-n {
  pointer-events: none;
}

// background sizee
.bs-19 {
  background-size: 19em;
}


// font sizes


.fs-1m {
  font-size: 1.5em;
}
.fs-1-3m {
  font-size: 1.3em;
}

.bs-cover {
  background-size: cover;
}