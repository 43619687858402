@import "../../pages/common/variables";

aside {
  .ct-truck-home {
    width: 18em;
    height: 9em;
    background-size: contain;

    h2 {
      font-size: 1em;
      width: 55%;
      text-align: center;
      line-height: 1em;
      margin-bottom: 3em;
    }
  }

  .content-icon-trending {
    cursor: pointer;
    display: flex;
    flex-flow: wrap;
    overflow: scroll;
    height: 8em;
    padding: 0;
    margin: 0;
    justify-content: space-evenly;
    align-items: center;
    background-position-x: left;
    flex-direction: column;
    overflow: initial;
      .icon-trending {
        background-image: url("../../rsc/assets/donut-sugg.png");
        background-repeat: no-repeat;
        background-position-y: center;
        background-position: center;
        height: 100%;
        width: 40%;
        background-size: contain;

      }
      .content-items-trending {
        width: 60%;
        h2 {
          color: #FFFFFF;
          text-shadow: 2px 3px 0 #969696, 8px 12px 3px #aba8a8;
          font-size: 3em;
        }
      }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.section-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;


  .content-column-welcome-two {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ct-item-discount {
    width: 50%;
  }
  .truck-size-xs {
    background-size: 27em;
    justify-content: center;
    background-position-y: bottom;
    background-position-x: right;

    .content-items-truck {
      padding: 0 0 2em;
    }
  }
  .title-filter {
    font-size: 1.2em;
    color: #4a4a4a;
  }

  .content-btn-filter {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.3em auto;

    &:hover {
      //opacity: 13%;
      .icon-filter {
        background-image: url("../../rsc/assets/icon-select-hov.png");
      }
    }

    .icon-filter {
      background-image: url("../../rsc/assets/icon-select.png");
      width: 2em;
      height: 2em;
      background-size: 2em;
      margin-left: 2em;
    }
  }
}


.icon-sale {
  background-image: url("../../rsc/assets/sale.jpeg");
  width: 100%;
  height: 12em;
  background-size: 18em;
  background-position: center;
  background-repeat: no-repeat;
}

.ct-icon-contact {

  border-radius: 2em;
  padding: 2em;
  width: 20%;
  height: 14em;

  &:hover {
    background-color: black;
    cursor: pointer;
  }
}
.icon-contact {
  background-image: url("../../rsc/assets/contact2.svg");
  background-size: contain;
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
}
@media (max-width: 700px) {


  .body-content-view {
    display: block;

    .content-view {
      height: 200%;
    }

  }
}

.body-content-view {
  display: flex;
  background-color: $fourth-colour;
  width: 100%;
  height: 100%;
  border-top-left-radius: 5em;
  // border-bottom-left-radius: 5em;
  // border: 4px solid $primary-colour;;
  border-bottom: none;
  flex-direction: column;

  .content-view {
    height: 100%;
    position: relative;
    display: block;
    width: 100%;

  }

  .cv2 {

    width: 98%;
    margin: auto;
    display: flex;
    // border: 2px solid $primary-colour;
    border-bottom: none;
    border-right: none;
    border-top-left-radius: 5em;

  }

  .body-view {
    overflow: initial;
    display: grid;
    grid-row-gap: 100px;
    grid-column-gap: 40px;
    grid-template-columns: auto auto auto auto;
  }

  @media (max-width: 1033px) {
    .body-view {
      grid-template-columns: auto auto;
    }
  }
  @media (max-width: 1550px) {
    .body-view {
      grid-template-columns: auto auto auto;
    }
  }
  @media (max-width: 1037px) {

    .body-view {
      grid-template-columns: auto auto;
      height: 100%;
      overflow: initial;
    }
  }
  @media (max-width: 600px) {
    .body-view {
      grid-template-columns: auto;
      width: 21em;
    }
  }

}

.column-filter-bag {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ts-third-column {
  //flex: 1 ;
  display: flex;
  justify-content: center;
  align-items: baseline;

  .reg-cp {
    font-size: .7em;
  }

  .content-faq {
    margin-left: 2em;
    cursor: help;
    width: 3em;
    height: 3em;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-faq {
      height: 2em;
      width: 2em;
      background-size: 2em;
      background-image: url("../../rsc/assets/icon-person-faq.png");

    }
  }
}
.column-create-bag {
  width: 24%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: .5em 0;



  .icons-pay {
    height: 2em;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon-cc {

      width: 61%;
      height: 2em;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../rsc/assets/icon-visa-mastercard.jpg");
    }

    .icon-efect {
      margin-left: -.7em;
      background-position: center;
      width: 17%;
      height: 2em;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../rsc/assets/icon-efecty.png");
    }

    .icon-wunion {
      margin-left: -.3em;
      background-position: center;
      width: 17%;
      height: 2em;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../rsc/assets/icon-western-union.png");
    }
  }

  .button-create-bag {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $zero-colour;
    border-radius: 2em;
    opacity: 20%;
    color: $primary-colour;

    &:hover {
      opacity: 100%;
    }
  }

  .icon-create {
    background-image: url("../../rsc/assets/icon-settings-black.png");
    background-size: 7em;
    height: 7em;
    width: 7em;

    &:hover {
      background-color: $primary-colour;
    }
  }

  span {
    font-weight: 900;
    font-size: 2em;
    letter-spacing: .1em;
    margin-left: 1em;
    height: 1em;
    line-height: 1em;
    margin-bottom: 1em;
  }
}

.cv2 {
  width: 98%;
  margin: auto;
  display: flex;
  //border: 2px solid $primary-colour;
  border-bottom: none;
  border-right: none;
  height: auto;
  flex: 12;

}

@media (max-width: 1400px) {
  .size3 {
    // display: none;
  }

}

@media (max-width: 800px) {
  .size1 {
    display: none;
  }

}

@media (max-width: 800px) {
  .size3 {
    // display: none;
  }
  .content-view {
    display: block;
    width: 100%;
  }
  .cv2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    height: 81%;
    position: relative;
  }
  .column-filter-bag {
    width: 100%;
    flex-wrap: wrap;
    height: 100%;
  }
  .column-create-bag {
    width: 100%;
  }

  .size2 {
    overflow: scroll;
    height: 100%;
  }

  .body-view {
    height: 67%;
  }

}

.content-categorys {
  width: 95%;
  margin-bottom: 3em;
  //padding-right: 4em;

  .content-title {
    span {
      font-weight: 500;
      font-size: 2em;
      padding: 1em 0;
      color: $second-colour;


    }
  }

  .content-occasions {
    width: 100%;
    // padding-left: 1em;
    cursor: pointer;
    overflow: auto;

    min-height: 10em;
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
    grid-auto-rows: min-content;
    flex-direction: column;
    flex-wrap: wrap;
    grid-column-gap: 1em;
    grid-row-gap: 2em;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 700;
      font-variant-caps: all-petite-caps
    }

  }

  @media (max-width: 1400px) {


    .content-occasions {
      grid-template-columns: auto auto auto;
    }
  }
  @media (max-width: 1100px) {


    .content-occasions {
      grid-template-columns: auto auto;
    }
  }


  &:hover {
    border-bottom: 1px solid;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(245, 245, 245, 1) 77%, rgba(237, 237, 237, 1) 100%);
    border-radius: 2em;
    padding: 1em;
    width: 94%;

    .content-title {
      span {
        color: $primary-colour;
      }
    }
    span {
      color: $fourth-colour;
    }
  }
}

.content-btns {
  display: flex;
  flex-direction: row;
  height: 35%;
}


.cnt-item-ocation {
  //height: 7em;
  width: 7em;
  background-color: $zero-colour;
  opacity: 1;
  border-radius: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.5em;
  padding: 1em 3em;

  span {
    color: $fourth-colour;
    font-weight: 700;
  }

  &:hover {
    background-color: $second-colour;

    span {
      font-size: 1.2em;
      color: $fourth-colour;
    }
  }
}


.cio-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 11em;
  min-width: 7em;
  justify-content: center;
  align-items: center;
  text-align: center;

  .cio-im-s {
    background-color: $second-colour;
    padding: 0.5em 1em;
    border-radius: 4em;
    font-size: 1em;
    font-weight: 400;
    font-variant: all-petite-caps;
  }

  &:hover {
    .cio-im-s {
      color: $fourth-colour;
    }
  }
}

@media (max-width: 900px) {

  .content-occasions {
    max-height: 30em;

    grid-template-columns: auto;
  }
  .content-column-welcome-two {

    .ct-item-discount {
      flex-direction: column;
      width: 100%;
    }
  }

  .icon-sale {
    width: 100%;
    background-position: center;
  }

  .ct-icon-contact {
    width: 50%;
    background-position: center;
  }

  .content-column-welcome-two {
    flex-direction: column;
    width: 100%;

  }
  .content-services {
    align-items: center;
    justify-content: flex-start;
  }

}

.Content-Page {
  display: flex;
  flex-direction: column;
}

.bar-information {
  background-color: #ececec;
  margin: 0;
  padding: .1em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;
  .tx-bar {
    color: $second-colour;
    font-family: -webkit-body, serif;
    font-size: 0.9em;

  }
}

.ta-c {
  text-align: -webkit-center;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
  opacity: 0.9;

}

.tt-lc {
  text-transform: lowercase;
}

.tt-up {
  text-transform: uppercase;
}
.op-5 {
  opacity: 0.5;
}

.ic {
  height: 1.7em;
  width: 1.3em;
  margin-right: .5em;
  background-size: 1.3em;
  background-position: center;
  background-repeat: no-repeat;

}

.ic-service-c {
  background-image: url("../../rsc/assets/servicio-al-cliente.svg");
}

.ic-cambio {
  background-image: url("../../rsc/assets/cambio.svg");
}

.ic-email {
  background-image: url("../../rsc/assets/email.svg");
  padding-left: 1.3em;
  line-height: 10px;
}

.ic-dinero {
  background-image: url("../../rsc/assets/shield.svg");
  width: 3em;
  height: 3em;
  background-size: 1.8em;
  opacity: 0.77;
  background-color: white;
  border-radius: 100%;
  background-size: 2em;
}

@media(max-width: 600px) {
  .bar-information {
    display: contents;
  }

}

.d-n {
  display: none;
}



@media (max-width: 500px) {

  .content-view {
    display: block;

    .style-pzed {
      flex-direction: column;

    }
  }


}

@media (max-width: 900px) {


  .bar-information {
    .tx-bar {
      color: $fourth-colour;
    
  
    }
  }

  .content-column-welcome-two  {

    .truck-size-xs {
      background-size: 23em;

      .content-items-truck {
        margin: 0;
        width: auto;
        padding: 0 3em 0 0;
      }
    }
  }


}



.txt-sel-mod {
  color: #ffaeae;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  text-shadow: 0 0 black;
  font-size: 1.7em;
}



.content-search {
  margin-bottom: 3em;
}