@import "../../pages/common/variables";

.content-menu {
  flex: 7;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .text-step {
    color: $fourth-colour;
  }

  .active {
    opacity: 1;
  }

  span {
    cursor: pointer;
  }

  .divider-step {
    height: .07em;
    color: $bgnd;
    background-color: $bgnd;
    margin: 0 1em;
  }

  .content-step {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .icon-step {
    height: 2em;
    width: 2em;
    border-radius: 50%;
    border: 1px solid $bgnd;
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $bgnd;

  }

  .progress {
    color: $fourth-colour;
    border: 3px solid $fourth-colour;

  }

  .inactive {
    opacity: 0.3;
    background-color: transparent;
    color: gray;
    border-color: gray;


  }
}

@media (max-width: 950px) {
  .content-menu {
    padding: 1em 0;
    overflow: auto;
    width: 100%;
    justify-content: start;
  }
}