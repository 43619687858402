@import "../../pages/common/variables";


.content-sub-header {
  flex: 8;
  display: flex;
  flex-direction: row;
  height: 1em;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0;
}