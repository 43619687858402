@import "../../pages/common/variables";

.content-info-alert {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .content-alert {
    height: 90%;
    // height: 30%;
    width: 90%;
    background-color: $fourth-colour;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    //border: 4px solid $primary-colour;

    .title-alert {
      color: $second-colour;

    }

    .actions-alert {
      display: flex;
      flex-direction: row;
      width: 90%;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 2em;

      div {
        cursor: pointer;
      }

      .btn-confirm {
        background-color: $primary-colour;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $fourth-colour;
        border-radius: 6em;

        span {
          padding: 1em 2em;
        }
      }
      .btn-cancel {
        color: $second-colour;
      }
    }
  }
}