@import "../../pages/common/variables";
@import "../../pages/common/carrousel-images";

.title-page {
  color: $fourth-colour;
  flex: 3;
  text-align: center;
  margin: initial;
}

.content-view {
  height: 100%;
  position: relative;
  width: 100%;
}

.sect-inf-prod {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sect-carrousel {
  -webkit-box-shadow: 10px 11px 105px -25px $fourth-colour;
  -moz-box-shadow: 10px 11px 105px -25px $fourth-colour;
  box-shadow: 10px 11px 105px -25px $fourth-colour;
  border-bottom-right-radius: 3em;
}
.sect-image {
  margin: 0 auto;
  
  @media (min-width: 768px) {
    width: 60%; /* Reduce el tamaño del carrusel en pantallas de escritorio */
  }
}

/* Imágenes dentro del carrusel */
.slick-slide img {
  width: 100%;
  height: auto;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .sect-image {
    width: 100%; /* Mantener el ancho completo en dispositivos móviles */
  }

  .slick-slide img {
    max-height: 300px; /* Ajusta la altura en móviles si es necesario */
    object-fit: cover;
  }
}


.sect-image {
  position: relative;

  .slick-slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
  
  }

 
}

.media-inf-view-prd {
  color: black;

  &:hover {
    color: $second-colour;
  }
}

.subit-sec {
  width: 100%;
  padding: 0 1em;
  overflow: auto;
}

.ct-cal {
  position: relative;
  font-size: 1em;
  background-color: $second-colour;
  padding: .6em;
  border-radius: 2em;
  color: $fourth-colour;
  font-weight: 900;
}

.ct-sub3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.ip-button-continue {
  padding: 17px;
}

.tx-it-q {
  color: silver;

  &:hover {
    color: $second-colour;
    font-size: 1em;
  }
}

@media (max-width: 700px) {
  .ct-sub3 {
    flex-direction: column;
  }

  .slick-prev,
  .slick-next {
    font-size: 20px; // Tamaño de las flechas en pantallas pequeñas
  }

  .slick-dots {
    bottom: -20px; // Ajustar la posición de las miniaturas en pantallas pequeñas
  }
}