@import "../../pages/common/variables";

.item-information {
  display: flex;
  height: 3em;
  width: 97%;
  align-items: center;
  border-radius: 1em;
  margin-top: .3em;


  .errortitlefield {
    color: red;
  }

  span {
    flex: 1;
    // opacity: 0.4;
    font-size: 0.875em;
    line-height: 1.43;
    letter-spacing: normal;
    color: $second-colour;
    margin-left: 1em;
    font-weight: 600;
  }

  select {
    width: 50%;
  }

  input, select, .edit-field {
    outline: none !important;
    flex: 2;
    background-color: $bgnd;
    border: none;
    font-size: 16px;
    color: $zero-colour;
    padding-left: 1em;
    // opacity: 0.5;
    font-size: 16px;

    &.error {
      border-color: red;
      border-right: .0625em solid red;
      color: red;
      border-radius: .5em;
    }
  }

  .edit-field {
    margin-left: 0;
    position: relative;
    display: flex;
    width: 1em;
    height: 85%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
  }

  select {
    border-radius: 0.25em;
    height: 83%;
    //border: .0625em solid #d0ccc6;
  }

  .capitalize {
    text-transform: capitalize;
  }

  border-bottom: .0625em solid #d0ccc6;


}
