.content-bar-search {
    position: relative;
    width: 33%;
  }
  
  .search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    font-size: 16px;
    color: #aaa;
  }
  
  input {
    padding-left: 30px; /* Espacio para la lupa */
    width: 100%;
    height: 40px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0;
  }
  
  li {
    padding: 8px;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  