@import "../../pages/common/variables";

.content-item-store {
  margin: 1em;
  border-top: 2px solid #F0FCF9;
  border-bottom: 2px solid #F0FCF9;
  height: 14em;
  border-radius: 1em;
  display: flex;
  flex-direction: row;
  padding: 1.5em .5em;
  position: relative;

  .content-image {
    flex: 1;
    height: 100%;

    .image-item {
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      background-position: center;
    }
  }

  .content-information {
    flex: 3;
    height: 100%;
    display: flex;

    .col1-information {
      display: flex;
      flex-direction: column;
      flex: 2;

      .cola1-inf {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-around;
        align-items: center;

        .title-cantidad {
          font-size: 0.8em;
          color: #c49586;
        }

        .sel-cantidad {
          height: 33px;
          margin-left: 2em;
          color: #c49586;
          border-color: #c49586;
          background-color: $fourth-colour;

          option {
            color: #c49586;

          }
        }

        .btn-delete {
          color: #cccccc;
          cursor: pointer;
          border: 1px dashed #F0FCF9;
          padding: .2em .9em;

          &:hover {
            border: 1px solid #cccccc;
            border-radius: .5em;
            font-weight: 600;
          }
        }
      }

      .content-contenido {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        margin: 1em 2em;
        padding: 1em;
        flex-wrap: wrap;

        span {
          color: $zero-colour;
          opacity: 33%;
          padding-right: 1em;
          font-size: 1em;
          line-height: 1.7em;
        }
      }
    }

    .col2-information {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;

      .btn-addition {
        padding: 1em;
        border: 1px solid #90d3d5;
        border-radius: 100%;
        height: 5em;
        width: 5em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1aa07c;
        font-size: 1em;
        font-weight: 900;
        cursor: pointer;

        &:hover {
          color: $zero-colour;
          //border-color: #c99380;
          background-color: #e9e9e9;
          font-size: 1em;
          width: 3.5em;
          height: 3.5em;
          font-weight: 700;
          border-style: dashed;
          border-width: .1em;
        }
      }

    }

    .title-item {
      font-size: 1.2em;
      color: $zero-colour;
      font-weight: 600;
    }
  }

  .content-price {
    display: flex;
    flex-direction: column;

    span {
      flex: 1;
    }

    .price {
      text-align: center;

    }
  }
}

.cnt-sel-quantity, .cnt-sold-out {
  height: 91%;
  z-index: 100;
  opacity: 0.9;
  border-radius: 1em;
  //padding: 42%;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-sel {
    font-size: 3em;
    color: white;
    text-align: center;
    padding-top: .4em;
  }

  .body-sel-quantity {

    

    justify-content: center;
    align-items: center;
    
    .field-count-interactive {
      display: flex;
      width: 7em;
    }
    
    .sel-quantity {
      font-size: 2em;
      border-radius: 5em;
      max-width: 100%;
      min-width: 20%;
      text-align: center;


    }
    button {
      font-size: 1.5em;
      cursor: pointer;
    }
  }

  .foot-inf-card {
    small {
      color: gray;
      padding: 1em;
    }
  }

}

@media (max-width: 700px) {

  .txt-delete-it {
    display: none;
  }
  .content-item-store {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 2em;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;

    .content-image {
      height: 12em;
      margin-bottom: 1em;
    }
  }
}
